<template>
<div id="bussinessManage">
  <!--头部搜索-->
  <div class="bg-white searchDom">
    <div class="flex align-center justify-end margin-bottom-24 header_search">
      <div class="flex align-center solid margin-right-24 padding-lr-16" style="height:32px;width: 300px;">
        <el-input @input="searKeyword" v-model="sendData.keyword" placeholder="请输入ID查询" suffix-icon="search"></el-input>
        <i style="cursor: pointer;"  class="el-icon-search" @click="searKeyword"></i>
      </div>
      <el-button @click="addArticleType" style="height: 32px;width: 68px;padding: 0;color: #FFFFFF;background-color: #4E9F5B;">添加分类</el-button>
      <el-button @click="addArticle" style="height: 32px;width: 68px;padding: 0;color: #FFFFFF;background-color: #4E9F5B;">添加文章</el-button>
      <el-button @click="exportData" style="height: 32px;width: 68px;padding: 0;color: #4E9F5B !important;border-color: #4E9F5B;">导出</el-button>
    </div>
  </div>
  <!--搜索列表-->
  <div class="searchList">
    <section id="blog" class="blog-area section-padding">
      <ul class="row align-center flex-start">
          <li class="blog-item margin-bottom-16" v-for="(v,i) in dataList" :key="i">
              <div class="single-blog">
                  <div class="blog-img">
                      <a href="javascript:void(0);">
                          <img :src="v.article_cover" alt="">
                      </a>
                  </div>
                  <div class="blog-content">
                      <div class="blog-title flex justify-between">
                          <h3>{{v.article_category_title}}</h3>
                      </div>
                      <div class="blog-text flex justify-between">
                          <h3 class="flex justify-between">ID:<p>{{v.article_id}}</p></h3>
                          <h3 class="flex justify-between">分类:<p>{{v.article_category_title}}</p></h3>
                          <h3 class="flex justify-between">状态:<p>{{v.article_state=='1'?'启用':'禁用'}}</p></h3>
                      </div>
                      <div class="blog-bottom-text-link flex justify-between" style="margin-top:-15px">
                          <span>{{v.article_release_time*1000|getDate}}</span>
                          <div class="flex ">
                            <!-- <a href="#" style="margin-right:10px">编辑</a>
                            <a href="#">删除</a> -->
                            <el-button @click="editArticle(v.article_id)" type="text" style="color: #4E9F5B;" size="small">编辑</el-button>
                            <el-button @click="delArticle(v.article_id)" type="text" style="color: #FF1A2E;" size="small">删除</el-button>
                          </div>
                      </div>
                  </div>
              </div>
          </li>
      </ul>
    </section>
  </div>
  <!--分页-->
  <div class="pageNum flex align-center justify-center" id="pageNum">
    <Pagination
      v-show="rows>1"
      :total="rows"
      @change = "getTabList"
      :page.sync= "sendData.page"
      :limit.sync="sendData.limit"/>
  </div>
    <el-dialog id="addCategory" title="添加分类" :visible.sync="showFenLeiModel" @close="closeAddDialog" :close-on-press-escape="false" 
    :close-on-click-modal="false">
      <div id="head_in" class="flex align-center justify-start margin-top-24">
        <el-input v-model="addCategory_title" placeholder="请输入内容" style="width: 50%;"></el-input>
        <el-button type="success" @click="addType"
          style="height: 32px;background-color: #4E9F5B;padding: 0 10px;margin-left: 16px;">添加
        </el-button>
      </div>
      <div class="margin-top-24">
         <el-table
            :header-cell-style="{background:'#F5F6F7',color:'#333333',fontsize:'14px'}" :data="categoryTypeList" stripe
            style="width: 100%">
            <el-table-column prop="article_category_id" label="分类ID" align="center">
            </el-table-column>
            <el-table-column prop="article_category_title" label="分类名称" align="center">
            </el-table-column>
            <el-table-column prop="article_count" label="视频数量" align="center">
            </el-table-column>
            <el-table-column prop="address" label="操作" align="center">
              <template slot-scope="scope">
                <el-button @click="editCategory(scope.row.article_category_id)" type="text" size="small" style="color: #4E9F5B;">编辑
                </el-button>
                <el-button @click="delCategory (scope.row.article_category_id)" type="text" size="small" style="color: #FF1A2E;">删除
                </el-button>
              </template>
            </el-table-column>
        </el-table>
      </div>
  </el-dialog>
</div>
</template>
<script>
import {article,articleDel,articleCategory,articleCategoryAdd,articleCategoryDel,articleCategoryEdit} from '@/api/http'
import Pagination from '@/components/pagination'
export default {
    data(){
      return{
        zzid: '',
        zz_userId: '',
        dataList: [], //表格数据
        /* 分页参数*/
        pages:1, //总页数
        rows:1, //总条数
        /* 弹窗参数*/
        showTabModel: false,
        modelTitle: '',
        modelUserId: 1,
        modelTabInfo: {},
        zz_addXj:false,
        //发送数据
        sendData: {
          keyword: '',
          page: 1,
          limit: 8,
          article_category_type: 3, //1: 新手教程, 2: 商学院
          article_category_id: '', //文章类别ID
        },
        currentUserId:'',
        //发展用户数表格数据
        tableList:{},
        addCategory_title:'',
        showFenLeiModel:false,
        categoryTypeList:[]
      }
    },
    created(){
      localStorage.removeItem('currentArticID')
      this.getTabList()
    },
    methods:{
      searKeyword(){
        console.log(this.sendData.keyword)
        if(!this.sendData.keyword) return this.getTabList();
        // return this.dataList.filter(v=>{
        //   console.log(v)
        //   return v.coupon_title.includes(this.sendData.keyword);
        // })
        var _this = this
        var newItems = []
        this.dataList.map(function(item){
          // console.log(item)
          if(!item.article_id.search(_this.sendData.keyword)){
            console.log(item)
            newItems.push(item)
          }
        })
        // return newItems
        console.log(newItems)
        this.dataList = newItems
      },
      //获取数据列表
      getTabList(){
        let that = this
        let obj = {}
        for (let item in this.sendData) {
          if (that.sendData[item] != '' && that.sendData[item] != '999') {
            obj[item] = that.sendData[item]
          }
        }
        article(obj).then(res=>{
          console.log('官网新闻中心',res.data)
          let {list,page,count,limit}=res.data.data
          console.log(list,page,count,limit)
          if(res.data.code == '1' && list){
            this.rows = count
            this.dataList = list
          }
        })
      },
      closeAddDialog(){
        this.showFenLeiModel = false
      },
      getCategoryTypeList(){
        articleCategory({
          article_category_type: 3
        }).then(res=>{
          console.log('官网新闻分类列表',res.data)
          let {list,page,count,limit}=res.data.data
          console.log(list,page,count,limit)
          if(res.data.code == '1' && list){
            this.rows = count
            this.categoryTypeList = list
          }
        })
      },
      delArticle(id){
        let that = this
          this.$confirm("此操作将删除当前文章内容, 是否继续?", "提示", {
				     confirmButtonText: "确定",
				     cancelButtonText: "取消",
				     type: "warning",
			    })
			    .then(() => {
            articleDel({article_id:id}).then(res=>{
              console.log('是否同意删除当前文章',res.data)
              if(res.data.code == '1'){
                  that.$message.success('删除成功')
                  that.getTabList()
              }else{
                  that.$message.error(res.info)
              }
            })
			    })
			    .catch(() => {
			    	this.$message({
			    		type: "info",
			    		message: "已取消操作",
			    	});
			    });
      },
      //添加文章
      addArticle(){
        this.$router.push('/newsAddArticle')
      },
      /* 编辑分类*/
      editCategory(id) {
        this.$prompt('请输入新的分类名称', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({
          value
        }) => {
          articleCategoryEdit({article_category_id:id,article_category_title:value}).then(res=>{
            console.log('编辑官网新闻分类列表',res.data)
            if(res.data.code=='1'){
              this.$message.success('编辑成功')
              this.showFenLeiModel = false
            }else{
              this.$message.error(res.info)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
      },
      //编辑新闻
      editArticle(id){
        console.log(id)
        localStorage.setItem('currentArticID',id)
        this.$router.push('/newsAddArticle')
      },
      /* 删除分类*/
      delCategory(id) {
        let that = this
        this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          articleCategoryDel({
             article_category_id: id
          }).then(res => {
            console.log('删除官网新闻分类列表：',res.data)
            this.showFenLeiModel = false
          if (res.data.code == '1') {
            this.$message.success('删除成功')
          } else {
            this.$message.error(res.info)
          }
        })
          // this.$emit('reFresh')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      //添加分类
      addType(){
        if(this.addCategory_title == ''){
          return false
        }
        let that = this
        articleCategoryAdd({
          article_category_type: 3,
          article_category_title: this.addCategory_title
        }).then(res => {
          console.log('添加官网新闻分类',res.data)
          if (res.data.code == '1') {
            this.$message.success('添加成功')
            that.showFenLeiModel = false
            this.getTabList()
          } else {
            this.$message.error(res.info)
          }
        })
      },
      //添加分类
      addArticleType(){
          this.showFenLeiModel = true
          this.getCategoryTypeList()
      },
      changeData() {
        this.sendData.page == '1'
        this.getStation()
      },
      emptyKeyword(e) {
        if (e.length == '0') {
          this.changeData()
        }
      },
      //导出
      exportData(){
        this.sendData.limit = 9999
        this.getStation()
      },
      closeResPassword(){
        this.showFenLeiModel = false
      },
      changePassword(){
        stationEdit({zz_id:this.zzid,zz_pwd:this.resetInfo}).then(res=>{
          console.log('重置密码',res)
        })
      },
      handleSizeChange(pageSize) {
        console.log('组件', pageSize)
        this.getTabList(this.currentUserId,this.tableList.page,pageSize)
      },
      handleCurrentChange(currentPage) {
        console.log(currentPage)
        this.getTabList(this.currentUserId,currentPage,this.tableList.limit)
      },
    },
    components:{
      Pagination
    }
}
</script>
<style>
  #bussinessManage .header_search .el-input__inner {
    height: 20px !important;
    border: none !important;
    padding: 0 !important;
  }
.blog-area {
	/* background: #fff6f6; */
}
.blog-area .blog-item{
  width: 24%;
  margin-right: 16px;
}
.blog-area .section-title {
	text-align: center;
  margin-bottom: 35px;
}
.blog-area .single-blog {
	position: relative;
	background: #ffffff;
	border-radius: 3px;
    margin-top: 30px;
	-webkit-box-shadow: 0px 5px 30px 0px rgba(148, 146, 245, 0.15);
	box-shadow: 0px 5px 30px 0px rgba(148, 146, 245, 0.15);
	overflow: hidden;
}
.blog-area .blog-img {
	position: relative;
	overflow: hidden;
}
.blog-area .single-blog img {
	-webkit-transition: all .5s;
	transition: all .5s;
  width: 100%;
  height: 200px;
}
.blog-area .single-blog:hover img {
	-webkit-filter: none;
	filter: none;
	-webkit-transform: rotate(3deg) scale(1.1, 1.1);
	-ms-transform: rotate(3deg) scale(1.1, 1.1);
	transform: rotate(3deg) scale(1.1, 1.1);
}
.blog-area .single-blog .blog-content {
	padding: 16px;
}
.single-blog .blog-content .blog-text p {
	color: #555;
}
.blog-area .single-blog .blog-content h3{
	font-size: 16px;
	font-weight: 500;
	color: #333;
	margin-bottom: 15px;
}
.blog-area .single-blog .blog-content h3 a:hover {
	color: #4ecfab;
	text-decoration: underline !important;
}
.single-blog .blog-content .blog-bottom-text-link a {
	font-size: 15px;
	font-weight: 500;
	color: #ffffff;
	background: #4ecfab;
    display: block;
    padding: 0px 10px;
}
.single-blog .blog-content .blog-bottom-text-link a:hover {
	color: #4ecfab;
	text-decoration: underline !important;
}
</style>